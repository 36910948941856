import type { NextPage } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";

type Props = {
  currentUrl: string;
  isApp: boolean;
};

const EnvironmentLanding = dynamic(() => import("feature/environment-landing"));

const Page: NextPage<Props> = ({ isApp }) => (
  <>
    <Head>
      <title>비닐하우스 견적, 시공 중개 - 팜모닝</title>
      <meta
        name="description"
        content="비닐하우스, 창고/농막, 스마트팜, 온실 예상 견적을 계산 해볼 수 있어요. 연락처를 남겨주시면 상세 견적서와 도면, 무료 시공 상담을 받아 볼 수 있어요. 팜모닝 & 브이하우스에서 신뢰할 수 있는 시공 업체 전문가를 만나보세요!"
      />
      <meta property="og:title" content="팜모닝 비닐하우스 견적 및 시공 상담" />
      <meta property="og:description" content="비닐하우스, 재배시설 견적 및 온실 시공 상담 서비스입니다." />
      <meta
        property="og:image"
        content={process.env.NEXT_PUBLIC_APP_URL ? `${process.env.NEXT_PUBLIC_APP_URL}/images/og-img.png` : ""}
      />
      <meta property="og:url" content={process.env.NEXT_PUBLIC_APP_URL} />
    </Head>
    <EnvironmentLanding key="EnvironmentLanding" isApp={isApp} />
  </>
);

export default Page;

export async function getServerSideProps({ req }) {
  const userAgent = req.headers["user-agent"];

  return { props: { isApp: /Android|iPhone/i.test(userAgent) } };
}
